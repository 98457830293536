import React from "react"

const Footer = () => (
  <footer className={'c-footer [ o-page__footer ]'}>
    <div className={'c-footer__contact'}>
      <a href="mailto:pieter@popcornready.be" title="Mail mij" className="link">pieter@popcornready.be</a>
      <a href="tel:0032477996619" title="Bel mij" className="link">+32 477 99 66 19</a>
    </div>
  </footer>
)

export default Footer
