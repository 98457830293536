import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import logo from "../images/popcornready-logo.svg"

const Header = ({ siteTitle }) => (
  <header className={'c-header [ o-page__header ]'}>
    <Link to={'/portfolio2020'} className={'c-header__logo'}>
      <img src={logo} alt="Popcorn Ready logo" />
    </Link>

    {/*
      <nav className={'c-header__nav'}>
        <Link to={'/'} className={'c-header__nav__item c-header__nav__item--active'}>
          Projecten
        </Link>
      </nav>
    */}
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
